import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { SubSink } from "subsink";
import { AutomapperService } from "../../../../../../../core/automapper/automapper.service";
import { LocalService } from "../../../../../../../core/storage/local.service";
import { DynamicFormEvent } from "../../../../../../../dynamic-forms/dynamic-form-event.model";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Autocomplete } from "../../../../../../../dynamic-forms/inputs/autocomplete/autocomplete.model";
import { Checkbox } from "../../../../../../../dynamic-forms/inputs/checkbox/checkbox.model";
import { CustomRadiobutton } from "../../../../../../../dynamic-forms/inputs/custom-radiobutton/custom-radiobutton.model";
import { ProviderDropdown } from "../../../../../../../dynamic-forms/inputs/provider-dropdown/provider-dropdown.model";
import { Radiobutton } from "../../../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../../../dynamic-forms/inputs/selectable-input.model";
import { TextboxType } from "../../../../../../../dynamic-forms/inputs/textbox/textbox-type.enum";
import { Textbox } from "../../../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { dateBetweenValidator } from "../../../../../../../dynamic-forms/validators/date-between.validator";
import { DocumentPageService } from "../../../../../../../shared/document/document-page.service";
import { BooleanHelper } from "../../../../../../../utilities/contracts/boolean-helper";
import { DateHelper } from "../../../../../../../utilities/contracts/date-helper";
import { NumberHelper } from "../../../../../../../utilities/contracts/number-helper";
import { ObjectHelper } from "../../../../../../../utilities/contracts/object-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { RegExHelper } from "../../../../../../../utilities/reg-Ex-Helper";
import { IcdService } from "../../../../chase-detail/chase-detail-chart/risk/diagnosis/icd.service";
import { ChaseDetailState } from "../../../../chase-detail/chase-detail-state.model";
import { LOCALSTORAGE_SELECTEDDIAGNOSISDX } from "../../local-storage-keys";
import { RiskDxDiagnosis } from "../dx-models/response/risk-dx-diagnosis.model";
import { RiskDxIcd } from "../dx-models/response/risk-dx-icd.model";
import { RiskDxService } from "../risk-dx.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "tr[app-risk-dx-admin-grid-row]",
  templateUrl: "./dx-admin-grid-row.component.html",
  styleUrls: ["./dx-admin-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DxAdminGridRowComponent implements OnInit, OnChanges, OnDestroy {
  @Input() disabled: boolean;
  @Input() riskChaseState: ChaseDetailState;
  @Input() icdGroup: RiskDxIcd;
  @Input() diagnosis: RiskDxDiagnosis;
  @Output() diagnosisChange: EventEmitter<RiskDxDiagnosis> = new EventEmitter();
  @Output() removeCoderAddedDiagnosis: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() onValid: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() onInvalid: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() resetAll: EventEmitter<any> = new EventEmitter<boolean>();
  private sink = new SubSink();
  icdCodeInput: Autocomplete;
  diagnosisInput: CustomRadiobutton;
  pageNumberInput: Textbox;
  providerInput: ProviderDropdown;
  diagnosisForm: FormGroup;
  startDateInput: Textbox;
  icdTitle: string;

  constructor(
    private readonly formService: FormService,
    private pageService: DocumentPageService,
    private readonly riskDxService: RiskDxService,
    private readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    private localService: LocalService,
    private readonly icdService: IcdService
  ) { }

  get modelId(): string {
    return this.icdGroup.modelId;
  }

  get categoryCode(): string {
    return this.icdGroup.hccId;
  }

  get enablePageNumberEdit(): boolean {
    return this.diagnosis.isCoderAddedDiagnosis || this.diagnosis.isAdmin;
  }
  get claimIdText(): string {
    return `ClaimID: ${this.diagnosis.claimId}`;
  }
  ngOnInit(): void {
    this.initializeForm();
    this.getProviderDetails();
    this.setProviderDropdownValue();
    this.OnChangeProviderDropdownValue();
    this.setValidInvalidDiagnosisValue();
    this.setPageNumberValue();
    this.updateFormWithProjectConfiguration(this.riskChaseState);
    this.diagnosisForm.get(this.diagnosisInput.key).valueChanges
    .subscribe(value => {
        this.isSelected(value);
    });
    this.diagnosisForm.get(this.pageNumberInput.key).valueChanges
    .pipe(debounceTime(2000))
    .subscribe(value => {
      this.updatePageNumber(value);
    });
  }
  private setValidInvalidDiagnosisValue(): void {
    const diagnosisStatus = this.diagnosis?.diagnosisStatus;
    const vrcCode = this.diagnosis?.vrcCode;
    const diagnosisFormControl = this.diagnosisForm.get(this.diagnosisInput.key);
    // Set value based on diagnosis status
    const value = diagnosisStatus === "V" || diagnosisStatus === "A"
                  ? "1"
                  : diagnosisStatus === "D" && StringHelper.isAvailable(vrcCode)
                  ? "0"
                  : null;

    diagnosisFormControl.setValue(value, { emitEvent: false });
  }
  private setProviderDropdownValue(): void {
    this.diagnosisForm.get(this.providerInput.key).setValue(this.diagnosis.providerSourceAliasId);
  }
  private setPageNumberValue(): void {
    this.diagnosisForm.get(this.pageNumberInput.key).setValue(this.diagnosis.pageNumber);
  }
  openPage(diagnosis: RiskDxDiagnosis): void {
    this.localService.put(LOCALSTORAGE_SELECTEDDIAGNOSISDX.key, this.diagnosis);
    this.pageService.updateOutsidePage(diagnosis.pageNumber);
  }

  isSelected(event: any): void {
    this.diagnosis.isSelected = true;
    const { diagnosisStatus } = this.diagnosis;
    const isStatusAvailable = StringHelper.isAvailable(diagnosisStatus);

    if (event === "1" && (!isStatusAvailable || (diagnosisStatus === "D"))) {
        this.onValid.emit(this.diagnosis);
        return;
    }
    if (event === "0" &&
        (!isStatusAvailable || ["A", "V"].includes(diagnosisStatus) || (diagnosisStatus === "D" && !StringHelper.isAvailable(this.diagnosis.vrcCode)))
    ) {
        this.onInvalid.emit(this.diagnosis);
        return;
    }

    this.resetAll.emit(this.diagnosis);
}

  getICDCode(code: string): string {
    const specificityDelimiter = ".";
    if (StringHelper.isAvailable(code) && RegExHelper.isValidICDCodePattern.test(code)) {
      return `${code.substring(0, 3)}${specificityDelimiter}${code.substring(3)}`;
    }
    return code;
  }

  formattedDxDate(date: string): string {
    return DateHelper.format(date, "MM/DD/YYYY");
  }

  private fillIcdData(): void {
    if (this.icdCodeInput.options) {
      const diagnosisCode = this.getICDCode(this.diagnosis.code);
      const cleanIcdCode = diagnosisCode.replace(/\./g, "");
      this.sink.add(
        this.icdService
          .getIcds(this.riskChaseState.chaseId, cleanIcdCode, this.diagnosis.dosFrom as string)
          .subscribe(
            (result: SelectableInput[]) => {
              const firstOption = result?.[0] as { value: string };
              if (firstOption?.value !== cleanIcdCode) {
                return;
              }

              const icdCodeControl = this.diagnosisForm.get(this.icdCodeInput.getMasterKey());
              icdCodeControl.setValue(firstOption);
              this.icdTitle = result?.[0]?.text.split("-")[1];
              this.changeDetector.markForCheck();
            }
          )
      );
    }
  }

  onIcdCodeChange(): void {
    this.diagnosis.code = this.diagnosisForm.get(this.icdCodeInput.key)?.value?.value;
    this.riskDxService.resetValidationButtons(this.icdGroup);
  }

  getConfidenceScore(score: number): string {
    return NumberHelper.isGreaterThan(score, 0) ? `${score.toFixed(2)}%` : "N/A";
  }

  private initializeForm(): void {
    this.diagnosisInput = new CustomRadiobutton({
      key: "diagnosis",
      options: [
        new SelectableInput({ text: "", value: "1"}),
        new SelectableInput({ text: "", value: "0"}),
      ],
      disabled: this.disabled,
    });
    this.providerInput = new ProviderDropdown({
      key: "Provider",
      label: "Provider",
      disabled: this.disabled,
      placeholder: "Select Provider",
    });
    this.startDateInput = new Textbox({
      key: "startDate",
      label: "Date From",
      disabled: this.disabled,
      value: this.diagnosis.dosFrom,
      validators: [Validators.required],
      errorMessages: {
        required: "Date from is required",
      },
    });
    this.pageNumberInput = new Textbox({
      key: "pageNumber",
      label: "Page Number",
      disabled: this.disabled,
      classOverride: "control-page-number",
      type: TextboxType.NUMBER,
      validators: [
        Validators.min(1),
        Validators.pattern(RegExHelper.wholeNumber),
      ],
      errorMessages: {
        required: "Enter a page number greater than 1",
        min: "Enter a page number greater than 1",
        pattern: "The page number must be an integer",
      },
    });
    this.icdCodeInput = new Autocomplete({
      key: "Icd",
      label: "ICD",
      searchMinimum: 2,
      disabled: this.disabled,
      serverFilter: (query, setOptionsFn) => {
        // Check if query is non-empty before making the API call
        if (StringHelper.isAvailable(query)) {
          this.icdService.getIcdsForDxCard(
            this.riskChaseState.chaseId,
            query,
            this.diagnosis.dosFrom as string,
            this.modelId,
            this.categoryCode
          ).subscribe(setOptionsFn);
        } else {
          // If query is empty, set empty options
          setOptionsFn([]);
        }
      },
      validators: [Validators.required],
      errorMessages: {
        required: "ICD Code is required",
        invalidicd: "Invalid ICD Code",
      },
    });
    this.createForm();

    if (this.diagnosis.isCoderAddedDiagnosis) {
      this.fillIcdData();
    }

  }

  private getProviderDetails(): void {
    this.providerInput.options = this.riskChaseState.providers.map(this.automapper.curry("Provider", "SelectableInput"));
    this.setSaveInfo();
    this.changeDetector.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const customRadioButtoncontrol = this.diagnosisForm?.get(this.diagnosisInput.key);
    const providerInputControl = this.diagnosisForm?.get(this.providerInput.key);
    const startDateInputControl = this.diagnosisForm?.get(this.startDateInput.key);
    const pageNumberInput = this.diagnosisForm?.get(this.pageNumberInput.key);
    const icdCodeInputControl = this.diagnosisForm?.get(this.icdCodeInput.key);

    if (!ObjectHelper.isEmpty(changes.diagnosis?.currentValue)) {
      customRadioButtoncontrol?.setValue(
        changes.diagnosis.currentValue?.diagnosisStatus === "A" || changes.diagnosis.currentValue?.diagnosisStatus === "V" ? "1" :
        changes.diagnosis.currentValue?.diagnosisStatus === "D" && changes.diagnosis.currentValue?.vrcCode ? "0" :
        null,
        { emitEvent: false }
    );
      if (changes.diagnosis.currentValue?.providerSourceAliasId !== changes.diagnosis.previousValue?.providerSourceAliasId) {
        providerInputControl?.setValue(changes.diagnosis.currentValue.providerSourceAliasId);
      }
      if (changes.diagnosis.currentValue?.pageNumber !== changes.diagnosis.previousValue?.pageNumber) {
        pageNumberInput?.setValue(changes.diagnosis.currentValue.pageNumber);
      }
    }

    if (changes.diagnosis?.previousValue !== changes.diagnosis?.currentValue) {
      this.diagnosisForm?.patchValue({
        startDate: this.diagnosis.dosFrom,
        pageNumber: this.diagnosis.pageNumber,
        icd: this.assignIcdValue(),
      });
    }

    if (BooleanHelper.isAvailable(changes.disabled?.currentValue)) {
      if (this.providerInput) {
        this.providerInput.disabled = changes.disabled.currentValue;
      }
      changes.disabled.currentValue ? customRadioButtoncontrol?.disable() : customRadioButtoncontrol?.enable();
      changes.disabled.currentValue ? providerInputControl?.disable() : providerInputControl?.enable();
      changes.disabled.currentValue ? startDateInputControl?.disable() : startDateInputControl?.enable();
      changes.disabled.currentValue ? pageNumberInput?.disable() : pageNumberInput?.enable();
      changes.disabled.currentValue ? icdCodeInputControl?.disable() : icdCodeInputControl?.enable();
    }

    if (changes.riskChaseState && this.providerInput) {
      this.getProviderDetails();
    }
  }


  private assignIcdValue(): void {
    if (this.diagnosis.isCoderAddedDiagnosis && this.diagnosisForm?.get(this.icdCodeInput.key)?.value?.value !== this.diagnosis?.code) {
      this.fillIcdData();
    }
  }

  private setSaveInfo(): void {
    const options = JSON.stringify(this.providerInput.options);
    const saveInfo = {
      chaseId: this.riskChaseState.chaseId,
      options,
    };
    const control = this.diagnosisForm.get(this.providerInput.getMasterKey());
    (control as any).saveInfo = saveInfo;
    this.providerInput.saveInfo = saveInfo;
  }

  private createForm(): void {
    this.diagnosisForm = this.formService.createFormGroup(
      [
        this.diagnosisInput,
        this.startDateInput,
        this.providerInput,
        this.pageNumberInput,
        this.icdCodeInput,
      ]
    );
  }

  private OnChangeProviderDropdownValue(): void {
    this.diagnosisForm.get(this.providerInput.key)
      .valueChanges.subscribe(providerSourceAliasId => {
        if (providerSourceAliasId !== this.diagnosis.providerSourceAliasId) {
          this.diagnosis.providerName = this.providerInput.options.find(x => x.value === providerSourceAliasId
          )?.label;
          this.diagnosis.providerSourceAliasId = providerSourceAliasId;
          this.resetAll.emit(this.diagnosis);
        }
      });
  }

  removeDiagnosis(): void {
    this.removeCoderAddedDiagnosis.emit(this.diagnosis);
  }

  private updateFormWithProjectConfiguration({ projectConfiguration }: ChaseDetailState): void {
    if (projectConfiguration != null) {
      const { reviewPeriodFromDate, reviewPeriodThruDate } = projectConfiguration;
      const dateValidators = [
        Validators.required,
        dateBetweenValidator(reviewPeriodFromDate, reviewPeriodThruDate),
      ];
      const datebetween = `The date must be between ${DateHelper.format(reviewPeriodFromDate)} - ${DateHelper.format(reviewPeriodThruDate)}`;

      this.diagnosisForm.get(this.startDateInput.getMasterKey())?.setValidators(dateValidators);
      this.startDateInput.validators = dateValidators;
      this.startDateInput.errorMessages = {
        ...this.startDateInput.errorMessages,
        datebetween,
      };

    }
  }

  validateDate(): void {
    const startDate = this.diagnosisForm.get(this.startDateInput.key);
    if (!DateHelper.format(startDate?.value, "MM/DD/YYYY")) {
      startDate?.setErrors({ "server-error": "Date should be in MM/DD/YYYY format" });
    } else {
      const currentDate = this.diagnosis.dosFrom;
      const formattedDate = DateHelper.format(startDate?.value);
      if (currentDate !== formattedDate) {
        this.diagnosisForm.get(this.startDateInput.key)?.setValue(formattedDate);
        this.diagnosis.dosFrom = startDate?.value;
        this.diagnosis.dosThrough = startDate?.value;
      }
    }
    this.changeDetector.markForCheck();
  }

  updatePageNumber(pageNumber: any): void {
    const currentPageNumber = this.diagnosis.pageNumber;
    if (pageNumber !== 0 && currentPageNumber !== pageNumber) {
      this.diagnosis.pageNumber = pageNumber;
      this.resetAll.emit(this.diagnosis);
    }
  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }
}

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { LoaderService } from "../../../../../core/interceptor/loader/loader.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../core/user/user.service";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { DynamicInput } from "../../../../../dynamic-forms/inputs/dynamic-input.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { GridColumnDefinition } from "../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ConfirmationModalComponent } from "../../../../../shared/panel/confirmation-modal/confirmation-modal.component";
import { ProgressBarUploadComponent } from "../../../../../shared/progress-bar/progress-bar-upload/progress-bar-upload.component";
import { TagItems } from "../../../../../shared/tags/model/tag-items.model";
import { TagSourceType } from "../../../../../shared/tags/model/tag-source-type.enum";
import { TagType } from "../../../../../shared/tags/model/tag-type.enum";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DigitalUnitConverterHelper } from "../../../../../utilities/contracts/digital-unit-converter-helper";
import { NumberHelper } from "../../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../../utilities/contracts/string-helper";
import { DocumentUploadRequestObjectType } from "../../../../api/document-upload/document-upload-request-objecttype.enum";
import { DocumentUploadRequest } from "../../../../api/document-upload/document-upload-request.model";
import { DocumentValidateResponseStatus } from "../../../../api/document-upload/document-upload-response.enum";
import { DocumentUploadRequestRetrievalType } from "../../../../api/document-upload/document-upload-retrievaltype.enum";
import { DocumentUploadService } from "../../../../api/document-upload/document-upload.service";
import { DocumentTypeName } from "../../../retrieval/retreival-document-review/document-type.enum";
import { RetrievalDocumentServiceService } from "../../../retrieval/retrieval-document-service.service";
import { retrievalMethodType } from "../retrieval-method-enum";
import { UploadBulkManageTagComponent } from "../upload-bulk-manage-tag/upload-bulk-manage-tag.component";
import { DocumentTagUploadRequest } from "../upload-bulk-tag.model";

export interface IUploadObject {
  presignedURL: string;
  fileName: string;
}

@Component({
  selector: "app-upload-bulk",
  templateUrl: "./upload-bulk.component.html",
  styleUrls: ["./upload-bulk.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class UploadBulkComponent implements OnInit, AfterViewInit {
  @ViewChild("uploadContainer") uploadContainer: ElementRef;
  @ViewChild(ProgressBarUploadComponent) progressBarUploadComponent: ProgressBarUploadComponent;
  @ViewChild("progressColumn") progressColumn: TemplateRef<ProgressBarUploadComponent>;
  @ViewChild("fileSizeColumn") fileSizeColumn: TemplateRef<any>;
  formatList: SelectableInput[] = [];
  selectedFileList: any[] = [];
  uploadedFileList: any[] = [];
  formData: FormData = new FormData();
  gridConfiguration = new GridConfiguration();
  gridData: any[] = [];
  gridSelection: any[] = [];
  validated: boolean;
  gridVisible = false;
  clients: SelectableInput[] = [];
  displayUploadContainer = true;
  allRowsSelected = false;
  maxFileSizeForBulkUploadInBytes: number;
  maxFileSizeForBulkUploadInMB: number;
  selectedFilesSize = 0;
  @ViewChild("myGrid") myGrid: any;
  @ViewChild("fileUpload") fileUpload: any;
  form: FormGroup;
  retrievalMethodOption: SelectableInput[];
  isUploadStarted = false;
  uploadingFileName: string;
  uploadWarningModalText: string;
  navigateSelection: Subject<boolean> = new Subject<boolean>();
  showProgressPercentage: boolean;
  interval = null;
  canNavigate = false;
  @ViewChild("uploadCancelWarning", { static: true }) uploadCancelWarning: ConfirmationModalComponent;
  hasSubmittedFiles = false;
  projectInput: Dropdown;
  originalRetrievalMethodInput: Dropdown;
  isUploadCanceled = false;
  selectedFilesToUpload = [];
  formatTypeInput: Dropdown;
  clientInput: Dropdown;
  sourceBulkUploadLit = "Bulk Upload";
  sourceBulkIntakeLit = "Bulk Intake";
  tagFormValues: any[] = [];
  tagType: TagType;
  tagActionType = "";
  tagFormData: any;
  objectIdsList: any[];
  tagItems: TagItems;
  showUploadedFileList = false;
  documentTagUploadRequest: DocumentTagUploadRequest;
  totalSelectedFileSize = 0;
  showValidationText = false;
  validationText = "File Validation in Progress...Results will appear below once complete.";
  @ViewChild(UploadBulkManageTagComponent) uploadBulkManageTagComponent: UploadBulkManageTagComponent;
  private presignedURLArray = new Array();
  private usePresignedURLs: boolean;
  private presignedURLList = [];
  private fileNameArray = new Array();
  private fileNameSaver = [];
  private dict = new Map();
  private fileNameToPresigned = new Map();
  private globalFileCounter = 0;
  private fileCounter = 0;
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    protected readonly automapper: AutomapperService,
    private changeDetector: ChangeDetectorRef,
    protected readonly formService: FormService,
    private messagingService: MessagingService,
    private documentUploadService: DocumentUploadService,
    private retrievalDocumentServiceService: RetrievalDocumentServiceService,
    private loaderService: LoaderService,
    private userService: UserService,
    private http: HttpClient
  ) {

  }

  ngOnInit() {
    this.createGrid();
    this.createForm();
    this.getFormatList();
    this.getOriginalRetrievalTypes();
    this.getMaxFileSizeForBulkUpload();
    this.tagType = TagType.DOCUMENT;
    this.tagActionType = "Add";
    this.objectIdsList = [];
    this.usePresignedURLs = this.userService.getUserToken().isPreSignedUrlUploadEnabled;
  }

  private createGrid(): void {
    this.gridConfiguration.columns = [
      new GridColumnDefinition({ field: "fileName", header: "File", width: "20%" }),
      new GridColumnDefinition({ field: "fileSize", header: "File Size", width: "20%", template: this.fileSizeColumn }),
      new GridColumnDefinition({ field: "message", header: "Validation", width: "20%", styles: this.getValidationColor }),
    ];

    this.gridConfiguration.pageSize = 10;
    this.gridConfiguration.showActionColumn = false;
    this.gridConfiguration.showExportAction = true;
  }
  private getValidationColor(value: string): object {
    if (StringHelper.isAvailable(value)) {
      return { color: value === "Passed - Added to Job Queue" ? "#57C785" : "#fe3b1f" };
    }
  }
  protected createForm(): void {
    this.projectInput = new Dropdown({
      key: "id",
      label: "Project",
      placeholder: "Select...",
      validators: [Validators.required],
      errorMessages: {
        required: "Project is required.",
      },
    });
    this.originalRetrievalMethodInput = new Dropdown({
      key: "originalRetrievalMethodInput",
      label: "Select Original Retrieval Method",
      placeholder: "Select Retrieval Method",
    });
    this.formatTypeInput = new Dropdown({
      key: "formatType",
      label: "Select Format Type",
    });

    this.form = this.formService.createFormGroup([this.projectInput, this.originalRetrievalMethodInput, this.formatTypeInput]);
  }

  getMaxFileSizeForBulkUpload() {
    this.documentUploadService.getMaxFileSizeForBulkUpload()
      .subscribe((data: number) => {
        this.maxFileSizeForBulkUploadInBytes = data;
        this.maxFileSizeForBulkUploadInMB = DigitalUnitConverterHelper.byteToMB(data);
        this.changeDetector.markForCheck();
      });
  }
  getProjectList(clientId?: number): void {
    this.documentUploadService
      .getProjectList(clientId)
      .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
      .subscribe(options => {
        this.projectInput = new Dropdown({ ...this.projectInput, options } as any);
        this.changeDetector.markForCheck();
      });
  }

  showFinish(): boolean {
    if (this.validated && this.gridData && this.gridData.filter(r => this.isValidForSelect(r)).length > 0) {
      return true;
    }

    return false;
  }

  showSelector(rowData): boolean {
    if (rowData && rowData.hasOwnProperty("status") && rowData.status !== DocumentValidateResponseStatus.Validated) {
      return false;
    }

    return true;
  }

  showDeleteButton(rowData) {
    if (rowData && rowData.hasOwnProperty("status") && rowData.status === DocumentValidateResponseStatus.DocumentAttachedAlready) {
      return true;
    }

    return false;
  }

  showReopenButton(rowData) {
    if (rowData && rowData.hasOwnProperty("status") && rowData.status === DocumentValidateResponseStatus.ChaseClosed) {
      return true;
    }

    return false;
  }

  isValidForSelect(rowData): boolean {
    if (rowData && rowData.hasOwnProperty("status") && rowData.status !== DocumentValidateResponseStatus.Validated) {
      return false;
    }

    return true;
  }

  getFormatList() {
    this.formatList = [
      new SelectableInput({ text: "Select Format", value: null }),
      new SelectableInput({ text: "Chase Id", value: "chaseid" }),
      new SelectableInput({ text: "Client Chase Key", value: "sourcealiasid" }),
      new SelectableInput({ text: "Documents for Intake", value: "intake" }),
      new SelectableInput({ text: "Image Name", value: "imageName" }),
    ];
    this.formatTypeInput = new Dropdown({ ...this.formatTypeInput, options: this.formatList } as any);
  }

  async onFileSelect(event, fileUpload) {
    for (const file of event.files) {
      this.totalSelectedFileSize += file.size;
      const fileExtension = file.name.substr(file.name.lastIndexOf(".") + 1).trim().toLowerCase();
      if (fileExtension === "pdf") {
        if (NumberHelper.isLessThan(this.totalSelectedFileSize, fileUpload.maxFileSize, true)) {
          this.selectedFileList.push(file);

          if (this.usePresignedURLs) {
            this.fileNameSaver.push(file.name);
            this.formData.append("Size", this.totalSelectedFileSize.toString());
            const obj = {
              name: file.name,
              type: file.type,
            };
            this.http.post(`${this.baseApiUrl}medicalrecord/presignedurl`, JSON.stringify(obj), { headers: new HttpHeaders({ "Content-Type": "application/json" }) })
              .toPromise().then(async (x: IUploadObject) => {
                this.presignedURLArray.push(x.presignedURL);
                this.fileNameArray.push(x.fileName);
                this.dict.set(this.fileNameSaver[this.globalFileCounter], x.fileName);
                this.fileNameToPresigned.set(this.fileNameSaver[this.globalFileCounter], x.presignedURL);
                this.globalFileCounter++;
              });
          }

        } else {
          this.messagingService.showToast("File(s) upload size exceeds limit of 1.5GB", SeverityType.ERROR);
          this.totalSelectedFileSize -= file.size;
          break;
        }
      } else {
        this.messagingService.showToast("Invalid File Format!", SeverityType.ERROR);
      }
    }
    if (ArrayHelper.isAvailable(this.selectedFileList)) {
      this.showUploadedFileList = true;
    }
    this.changeDetector.detectChanges();
    this.setFileRemoveIconOnScroll(fileUpload);
  }

  setFileRemoveIconOnScroll(fileUpload): void {
    if (fileUpload.content.nativeElement.scrollHeight > fileUpload.content.nativeElement.clientHeight) {
      const removeIcon = fileUpload.content.nativeElement.getElementsByClassName("ui-button-icon-only") as HTMLCollectionOf<HTMLButtonElement>;
      if (removeIcon.length > 0) {
        let index = 0;
        while (index < removeIcon.length) {
          removeIcon[index].classList.add("inline-icon");
          index++;
        }
      }
    }
  }
  onFileRemove(file: any, index: number) {
    this.formData = new FormData();

    let i = this.selectedFileList.length;
    while (i--) {
      if (i === index && this.selectedFileList[i].name === file.name) {
        this.selectedFileList.splice(i, 1);
        this.totalSelectedFileSize -= file.size;
      } else {
        this.formData.append("Document", this.selectedFileList[i]);
      }
    }
  }
  clear() {
    this.isUploadCanceled = true;
    this.isUploadStarted = false;
    this.allRowsSelected = false;
    this.validated = false;
    this.gridData = [];
    this.selectedFileList = [];
    this.fileUpload.clear();
    this.gridSelection = [];
    this.selectedFilesToUpload = [];
    this.navigateSelection.next(false);
    this.hasSubmittedFiles = false;
    this.tagFormValues = [];
    this.tagFormData = [];
    this.objectIdsList = [];
    this.gridVisible = false;
    this.createForm();
    // TODO: Is there a better way to clear the options?
    this.projectInput = new Dropdown({ ...this.projectInput, options: null } as any);
    this.getFormatList();
    this.getProjectList();
    this.getOriginalRetrievalTypes();
    this.form.get(this.originalRetrievalMethodInput.key).clearValidators();
    this.form.controls.originalRetrievalMethodInput.reset();
    this.uploadBulkManageTagComponent.initializeSelectedTag();
    this.loaderService.isLoading.next(false);

  }

  setOriginalRetrievalMethodInputValidation() {
    this.originalRetrievalMethodInput = new Dropdown({
      ...this.originalRetrievalMethodInput,
      validators: [
        Validators.required,
      ],
      errorMessages: {
        required: "Retrieval Method is required.",
      },
    } as any);
    this.setValidators([this.originalRetrievalMethodInput]);
    this.formService.markAllAsTouched(this.form);
    this.changeDetector.markForCheck();
  }

  private setValidators(controls: DynamicInput[]): void {
    controls.forEach(control => {
      this.form.get(control.key).setValidators(control.validators);
    });
  }

  validateDocuments(): void {
    this.setOriginalRetrievalMethodInputValidation();
    if (this.form.invalid && !this.isRetrievalTypeMethodselected) {
      this.formService.markAllAsTouched(this.form);
      this.changeDetector.markForCheck();
      return;
    }
    this.allRowsSelected = false;
    this.gridVisible = false;
    this.gridSelection = [];
    this.objectIdsList = [];
    this.uploadedFileList = [];
    if (ArrayHelper.isAvailable(this.selectedFileList) && NumberHelper.isGreaterThan(this.selectedFileList.length, 0)) {
      // make sure all required fields populated
      if (this.isClientChaseKeySelected && !this.selectedProject) {
        this.messagingService.showMessage("Please select a project for Source Alias matching", SeverityType.ERROR);
        return;
      } else if (this.isClientChaseKeySelected && this.form.invalid) {
        this.formService.markAllAsTouched(this.form);
        this.changeDetector.markForCheck();
        return;
      }
      this.fileUpload.clear();
      if (ArrayHelper.isAvailable(this.selectedFileList) && NumberHelper.isGreaterThan(this.selectedFileList.length, 0)) {
        this.formData.append("documentTypeId", DocumentTypeName.DocTypeMedicalRecord.toString());
        this.formData.append("objectType", this.getObjectTypeForFormat(this.selectedFormat).toString());
        this.formData.append("objectTypeKeyType", (this.selectedFormat).toString());
        this.formData.append("tagTextCsv", this.getSelectedTags());
        this.formData.append("fileNames", this.fileNameSaver.join(","));
        if (NumberHelper.isAvailable(this.getProjectId)) {
          this.formData.append("projectId", this.getProjectId.toString());
        }
      }
      this.showValidationText = true;
      this.uploadedFileList = this.selectedFileList;
      this.selectedFileList = [];
      this.isUploadStarted = true;
      this.documentUploadService.bulkvalidate(this.formData).subscribe(items => {
        this.validated = true;
        this.showValidationText = false;
        this.gridVisible = true;
        const invalidFilesLength = items.filter(x => x.status !== 1).length;
        this.gridData = items;
        for (const item of this.gridData) {
          const files = this.uploadedFileList.find(x => x.name === item.fileName);
          const index = this.gridData.findIndex(x => x.fileName === item.fileName);
          if (files != null && NumberHelper.isGreaterThan(index, -1)) {
            this.gridData[index].fileSize = this.bytesToSize(files.size);
          }
        }

        this.gridData.sort(a => (a.status === 1) ? 1 : -1);
        this.gridData.map(r => {
          r.message = r.status === 1 ? "Passed - Added to Job Queue" : r.message;
          return r;
        });
        const getItemsToUpload = items.filter(x => x.status === 1);
        if (getItemsToUpload.length > 0) {
          this.gridSelection = getItemsToUpload;
          this.uploadDocuments();
        } else {
          this.clearPreSignedUploadObjects();
        }
        if (invalidFilesLength === this.uploadedFileList.length) {
          this.isUploadStarted = false;
          this.formData = new FormData();
        }

        this.showUploadedFileList = false;
        this.changeDetector.detectChanges();
        this.scrollToBottom();
      });
    }
  }
  private bytesToSize(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) { return "n/a"; }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
    if (i === 0) { return `${bytes} ${sizes[i]})`; }
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
  }

  private clearPreSignedUploadObjects(): void {
    this.presignedURLArray = [];
    this.presignedURLList = [];
    this.fileNameArray = [];
    this.fileNameSaver = [];
    this.dict = new Map();
    this.fileNameToPresigned = new Map();
    this.globalFileCounter = 0;
    this.formData = new FormData();
    this.uploadedFileList = [];
    this.isUploadStarted = false;
  }

  private getSelectedTags(): any {
    if (this.tagActionType === "Add") {
      this.tagFormData = this.tagFormValues;
      const tagTextCsv = this.tagFormData.addedTagList != undefined ? this.tagFormData.addedTagList.map(x => x.text).join(",") : "";
      return tagTextCsv;
    }
    return;
  }
  async uploadDocuments() {
    this.validated = false;
    this.isUploadCanceled = false;
    this.changeDetector.markForCheck();
    this.selectedFilesToUpload = this.gridSelection;
    this.gridSelection = [];
    this.messagingService.showToast("File validation is complete, your files are now in the process of uploading", SeverityType.SUCCESS);

    if (this.usePresignedURLs) {
      const fileArray: any = [];
      for (const gridRow of this.selectedFilesToUpload) {
        this.isUploadStarted = true;
        if (this.isValidForSelect(gridRow)) {
          this.uploadedFileList.map(r => {
            if (r.name === gridRow.fileName) {
              fileArray.push(r);
            }
          });
        }
      }
      const filePromises = fileArray.map((file, index) => {
        // Return a promise per file
        return this.uploadFiles(file, index);
      });
      await Promise.all(filePromises).catch(res => {
        return false;
      });
      for (const key of this.dict.keys()) {
        if (key) {
          this.formData.append(this.dict.get(key), this.dict.get(key));
          this.presignedURLList.push(this.dict.get(key));
        }
      }
    }
    this.fileCounter = 0;
    for (const gridRow of this.selectedFilesToUpload) {
      this.isUploadStarted = true;
      if (this.isValidForSelect(gridRow)) {
        // find matching File element
        const file = this.uploadedFileList.filter(r => r.name === gridRow.fileName);
        if (file.length > 0) {
          await this.uploadFile(gridRow, file);
        }
      }
    }
    this.clearPreSignedUploadObjects();
    this.changeDetector.detectChanges();
    if (this.hasInvalidFiles) {
      this.uploadCancelWarning.hide();
      this.canNavigate = true;
      this.hasSubmittedFiles = false;
      this.selectedFileList = [];
      this.uploadedFileList = [];
      this.isUploadStarted = false;
    }
  }

  uploadFiles(file, j): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        this.http.put(this.presignedURLArray[j], file).toPromise().then(() => {
          resolve(true);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  async uploadFile(gridRow, file): Promise<void> {
    const objectType = this.getObjectTypeForFormat(this.selectedFormat);
    this.formData = new FormData();
    this.formData.append("0", this.presignedURLList[this.fileCounter]);
    this.formData.append("size", file[0].size);
    this.formData.append("NumFiles", "1");
    this.formData.append("OFN0", gridRow.fileName);
    this.fileCounter++;

    const tagTextCsv = this.getSelectedTags();
    const tagTypeId = this.tagType;
    const tagSourceId = TagSourceType.USER;
    const tagItems = this.tagFormData.addedTagList;

    const documentUploadRequest = new DocumentUploadRequest(
      gridRow.fileName,
      DocumentTypeName.DocTypeMedicalRecord,
      objectType,
      gridRow.chaseId,
      this.getProjectId,
      this.retrievalMethodId,
      objectType === DocumentUploadRequestObjectType.Intake ? this.sourceBulkIntakeLit : this.sourceBulkUploadLit,
      this.formData,
      tagTextCsv,
      tagTypeId,
      tagSourceId,
      tagItems
    );

    this.uploadingFileName = gridRow.fileName;
    this.changeDetector.markForCheck();
    if (this.usePresignedURLs) {
      await this.documentUploadService.uploadPresigned(documentUploadRequest).toPromise().catch(error => {
        // This is for errors that occur before a completed Response can be returned from API
        this.setUploadError(gridRow);
      }).then((response: any) => {
        if (response?.body?.status !== "Success") {
          this.setUploadError(gridRow);
          this.messagingService.showToast(response?.body?.message || "Cannot upload Document, please make sure it is a PDF and is not Password protected", SeverityType.ERROR);
        }
      });
    } else {
      await this.documentUploadService.upload(documentUploadRequest).toPromise().catch(error => {
        // This is for errors that occur before a completed Response can be returned from API
        this.setUploadError(gridRow);
      }).then((response: any) => {
        if (response?.body?.status === "Success") {
          this.objectIdsList = this.objectIdsList || [];
          this.objectIdsList.push(response?.body?.documentQueueId);
          if (this.selectedFilesToUpload.length === this.objectIdsList.length && StringHelper.isAvailable(this.getSelectedTags())) {
            this.uploadTagDocuments();
          }
          if (this.selectedFilesToUpload.length === this.objectIdsList.length) {
            this.isUploadStarted = false;
            this.formData = new FormData();
            this.uploadedFileList = [];
          }
        } else {
          this.setUploadError(gridRow);
          this.messagingService.showToast(response?.body?.message || "Cannot upload Document, please make sure it is a PDF and is not Password protected", SeverityType.ERROR);
        }
      });
    }

    this.changeDetector.markForCheck();
  }
  getObjectTypeForFormat(format: string): number {
    let objectType = DocumentUploadRequestObjectType.Chase;

    switch (format) {
      case "intake":
      case "imageName":
        objectType = DocumentUploadRequestObjectType.Intake;
        break;

      default:
      case "chaseid":
      case "sourcealias":
        objectType = DocumentUploadRequestObjectType.Chase;
        break;
    }

    return objectType;
  }

  get isClientChaseKeySelected(): boolean {
    return this.selectedFormat === "sourcealiasid";
  }

  get getProjectId(): number | null {
    return this.isClientChaseKeySelected ? this.form.get(this.projectInput.key).value : null;
  }
  // trick to get grid to refresh so new column values display
  refreshGridRow() {
    this.gridData = this.gridData.slice();
    this.changeDetector.markForCheck();
  }

  deleteFileClick(rowData) {
    this.documentUploadService.deleteActiveDocument(rowData.chaseId).subscribe(data => {

      const gridIndex = this.gridData.findIndex(x => x.chaseId === rowData.chaseId);
      this.gridData[gridIndex].message = "Chase document Deleted";
      this.gridData[gridIndex].status = DocumentValidateResponseStatus.DocumentDeleted;
      this.refreshGridRow();
    });
  }

  reOpenChaseClick(rowData) {
    this.documentUploadService.reOpenChase(rowData.ChaseId).subscribe(data => {
      const gridIndex = this.gridData.findIndex(x => x.chaseId === rowData.chaseId);
      this.gridData[gridIndex].message = "Chase Reopened";
      this.gridData[gridIndex].status = DocumentValidateResponseStatus.ChaseReOpened;
      this.refreshGridRow();
    });
  }

  trackByIndex(index, item) {
    return index;
  }

  toggleViewUpload(): void {
    this.displayUploadContainer = !this.displayUploadContainer;
  }

  get isDisplay(): boolean {
    return ArrayHelper.isAvailable(this.gridSelection) || ArrayHelper.isAvailable(this.selectedFilesToUpload);
  }

  get cancelBtnClass(): string {
    return this.isDisplay ? "cancel-button--left" : "";
  }

  get filesSelectedTotal(): string {
    if (this.allRowsSelected) {
      return (this.myGrid.data.length === 1) ? `${this.myGrid.data.length} File Selected` : `${this.myGrid.data.length} Files Selected`;
    }

    if (ArrayHelper.isAvailable(this.gridSelection)) {
      return (this.gridSelection.length === 1) ? `${this.gridSelection.length} File Selected` : `${this.gridSelection.length} Files Selected`;
    }
    return "";
  }

  get invalidFilesTotal(): string {
    if (ArrayHelper.isAvailable(this.gridData)) {
      const total = this.gridData.filter(r => !this.isValidForSelect(r)).length;
      return (total === 0) ? "" : (total === 1) ? `${total} INVALID FILE` : `${total} INVALID FILES`;
    }
    return "";
  }
  get getSelectedFileText(): string {
    if (ArrayHelper.isAvailable(this.gridData)) {
      return `Please review validation results.`;
    }
  }

  get doesExceedMaxFileSize(): boolean {
    this.selectedFilesSize = 0;
    if (this.allRowsSelected) {
      if (this.myGrid.data.length === 1) {
        this.selectedFilesSize = this.myGrid.data[0].fileSize;
      } else {
        this.myGrid.data.forEach(element => {
          this.selectedFilesSize = this.selectedFilesSize + element.fileSize;
        });
      }
    }

    if (ArrayHelper.isAvailable(this.selectedFilesToUpload) || ArrayHelper.isAvailable(this.gridSelection)) {
      if (this.selectedFilesToUpload.length === 1 || this.selectedFilesToUpload.length === 1) {
        this.selectedFilesSize = ArrayHelper.isAvailable(this.selectedFilesToUpload) ? this.selectedFilesToUpload[0].fileSize : this.gridSelection[0].fileSize;
      } else {
        if (ArrayHelper.isAvailable(this.selectedFilesToUpload)) {
          this.selectedFilesToUpload.forEach(element => {
            this.selectedFilesSize = this.selectedFilesSize + element.fileSize;
          });
        } else {
          this.gridSelection.forEach(element => {
            this.selectedFilesSize = this.selectedFilesSize + element.fileSize;
          });
        }
      }
    }

    return NumberHelper.isGreaterThan(this.selectedFilesSize, this.maxFileSizeForBulkUploadInMB, true);
  }

  get filesUploaded(): boolean {

    return ArrayHelper.isAvailable(this.selectedFileList);
  }

  private getOriginalRetrievalTypes(): void {
    this.retrievalDocumentServiceService
      .getRetrievalTypes()
      .subscribe(options => {
        for (const retrievalMethod of this.filteredRetrievalTypes) {
          const indexOfRetrievalMethod = options.map(option => option.label).indexOf(retrievalMethod);
          options.splice(indexOfRetrievalMethod, 1);
        }
        this.originalRetrievalMethodInput = new Dropdown({ ...this.originalRetrievalMethodInput, options } as any);
        this.retrievalMethodOption = options;
        this.changeDetector.markForCheck();
      });
  }

  get selectedRetrievalMethodId(): number {
    return this.form.get("originalRetrievalMethodInput").value;
  }

  get isRetrievalMethodSelected(): boolean {
    return NumberHelper.isAvailable(Number(this.selectedRetrievalMethodId)) && NumberHelper.isGreaterThan(Number(this.selectedRetrievalMethodId), 0);
  }

  get retrievalMethodId(): number {
    return this.isRetrievalMethodSelected ? Number(this.selectedRetrievalMethodId) : Number(retrievalMethodType.BulkUpload);
  }

  get retrievalMethodName(): string {
    return this.retrievalMethodOption.find(option => option.value === this.selectedRetrievalMethodId).text;
  }

  get filteredRetrievalTypes(): string[] {
    const filteredDocumentUploadRetrievalTypes = [
      "Invoice Upload",
      DocumentUploadRequestRetrievalType[DocumentUploadRequestRetrievalType.FTP],
      DocumentUploadRequestRetrievalType[DocumentUploadRequestRetrievalType.Intake],
      DocumentUploadRequestRetrievalType[DocumentUploadRequestRetrievalType.SMRC],
    ];
    return filteredDocumentUploadRetrievalTypes;
  }

  get isRetrievalTypeMethodselected(): boolean {
    return this.isRetrievalMethodSelected && this.form.controls.originalRetrievalMethodInput.touched;
  }

  get hasSuccessMessage(): boolean {
    return ArrayHelper.isAvailable(this.gridData.filter(x => x.message === "Passed"));
  }

  get reportIcon(): string {
    return "fas fa-redo-alt";
  }
  scrollToBottom(): void {
    this.uploadContainer.nativeElement.offsetParent.scrollTop = this.uploadContainer.nativeElement.offsetParent.scrollHeight + 100;
  }


  ngAfterViewInit(): void {
    this.createGrid();
  }

  setUploadError(gridRow: any): void {
    this.selectedFilesToUpload.map(x => { x.hasError = x === gridRow ? true : x.hasError; return x; });
  }

  get hasInvalidFiles(): boolean {
    return this.selectedFilesToUpload?.filter(x => x.hasError === true).length > 0;
  }

  onSelectFormatType() {
    this.getProjectList();
  }

  get hasSubmittedFilesAndExceedMaxFileSize(): boolean {
    return this.doesExceedMaxFileSize || this.hasSubmittedFiles;
  }

  get showCancelButton(): boolean {
    return this.selectedFormat && !this.hasInvalidFiles;
  }

  get showSubmitButton(): boolean {
    return this.isDisplay && !this.hasInvalidFiles;
  }

  showProgressStatus(rowData): boolean {
    return this.isValidForSelect(rowData) && !rowData.hasError;
  }

  get selectedFormat(): string {
    return this.form.get(this.formatTypeInput.key).value;
  }
  get selectedProject(): any {
    return this.form.get(this.projectInput.key).value;
  }
  onSelectedTagData(selectedTag): void {
    this.tagFormValues = selectedTag;
  }
  uploadTagDocuments() {
    this.documentTagUploadRequest = new DocumentTagUploadRequest({
      tagTextCsv: this.getSelectedTags(),
      tagTypeId: this.tagType,
      tagSourceId: TagSourceType.USER,
      tagItems: this.tagFormData.addedTagList,
      objectIds: this.objectIdsList,
    });
    this.documentUploadService.documentTagBulkUpload(this.documentTagUploadRequest).subscribe(() => {
      this.objectIdsList = [];
      this.uploadedFileList = [];
      this.changeDetector.markForCheck();
    });
  }

}
